<template>
	<div class="p-fluid">
        <div class="p-grid">
			<div class="p-col-12 p-md-12 p-lg-12">
                <label>{{$t('Name')}}</label>
                <InputText v-model="$store.state.person.name" type="text" id="name" />
            </div>
			<div class="p-col-12 p-md-12 p-lg-6">
                <label>ID / {{$t('Passport')}}</label>
                <InputText v-model="$store.state.person.ident_passport" type="text" id="name" />
            </div>            
			<div class="p-col-12 p-md-12 p-lg-6">
                <label>{{$t('Nationality')}}</label>
				<Dropdown v-model="$store.state.person.nationality" :options="nationalitys" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
            </div>
			<div class="p-col-12 p-md-12 p-lg-12">
                <label>{{$t('Residence')}}</label>
				<Textarea v-model="$store.state.person.residence" rows="2"/>
            </div>
			<div class="p-col-12 p-md-12 p-lg-8">
                <label>{{$t('Profession')}}</label>
                <InputText v-model="$store.state.person.profession" type="text" id="name" />
            </div>
			<div class="p-col-12 p-md-12 p-lg-4">
                <label>{{$t('Politically exposed person')}} (PEP)</label>
                <SelectButton v-model="$store.state.person.pep" :options="options" :optionLabel="i18n.locale()"/>
            </div>
        </div>    
        <div class="p-grid">
			<div class="p-col-12 p-md-12 p-lg-8" v-if="alls">
				<label>{{$t('Email')}}</label>
				<InputText v-model="$store.state.person.email" type="email"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-4" v-if="alls">
				<label>{{$t('Telephone')}}</label>
				<InputMask v-model="$store.state.person.telephone" mask="+99999 999999"/>
			</div>
			<div class="p-col-6 p-md-6 p-lg-3">
				<div v-if="$store.state.person.doc_passport">
					<img style="width: 100%;" :src="url+'upload/'+$store.state.person.doc_passport" @click="View($store.state.person.doc_passport)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(1,$store.state.person.doc_passport)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Upload image')" uploadLabel="Cargar" mode="basic" :name="'doc_'+token" :url="url+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="1000000" @upload="Uploader(1,'doc_'+token)" :auto="true"/>
				</div>
			</div>
			<div class="p-col-6 p-md-6 p-lg-3" v-if="alls">
				<div v-if="$store.state.person.form">
					<img style="width: 50px;" src="images/pdf.png" @click="View($store.state.person.form)"/>
					<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="Delimg(2,$store.state.person.form)" v-tooltip.bottom="$t('Delete')"/>
				</div>
				<div v-else>
					<FileUpload :chooseLabel="$t('Signed form')" uploadLabel="Cargar" mode="basic" :name="'form_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(2,'form_'+token)" :auto="true"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import API from "../service/API";
var Consult = new API('Registration','Uploader');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";

export default {
    props: {
		alls: {
			type: Boolean,
			default: true,
		},
		nations: {
			type: Array,
			default: null,
		},
		option: {
			type: Array,
			default: null,
		}
	},
    data() {
        return {
            size: '40vw',
            nationalitys: this.nations,
            options: this.option,
			token: null,
			url: process.env.VUE_APP_API,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		const tokgen = new Token(256);
        this.token = tokgen.generate();
    },
    watch: {
	},
    methods: {
		View(index){
			window.open(this.url+'upload/'+index, '_blank');
		},
		Uploader(type,data) {
			Consult.Process('Add',{
				inf: data,
			}).then(response => {
				//this.$store.state.error =response;
				this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
				if(response.result){	
					if(type==1){this.$store.state.person.doc_passport = response.result;}				
                    if(type==2){this.$store.state.person.form = response.result;}		
				} 
                const tokgen = new Token(256);
                this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
		Delimg(type,img) {
			Consult.Process('Del',{
				inf: img,
			}).then(response => {
				//this.$store.state.error =response;
				this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
				if(response.result){				
					if(type==1){this.$store.state.person.doc_passport = null;}				
                    if(type==2){this.$store.state.person.form = null;}		
				} 
				this.$store.commit('Loading');
			});			
		},

    },


}
</script>